import { FormControlLabel, Switch, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { Bookmark, NewBookmark } from '../../../models'
import { FormField } from '../../admin/shared'
import {
  useAddBookmarkMember,
  useCreateBookmark,
  useGetBookmarkMember,
  useGetLicense,
  useUpdateBookmark,
} from '../../../hooks'
import { useRemoveBookmarkMember } from '../../../hooks/mutations/useRemoveBookmarkMember'
import { useRecoilState, useRecoilValue } from 'recoil'
import { bookmarkAtom } from '../../../state/bookmarkAtom'
import { reportStateAtom } from '../../../state'
import CustomDialog from '../../admin/shared/dialog/dialog'

export function BookmarkDialog(props: BookmarkDialogProps) {
  const { open, onClose, reportId, bookmark } = props

  const [icon, setIcon] = useState(bookmark?.icon || 'default')

  useEffect(() => {
    setIcon(bookmark?.icon || null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmark])

  return (
    <BookmarkEditForm
      onClose={onClose}
      reportId={reportId}
      icon={icon}
      bookmark={bookmark}
      open={open}
    />
  )
}

type BookmarkDialogProps = {
  open: boolean
  onClose: () => void
  reportId: string
  bookmark?: Bookmark // existing bookmark to edit
}

function containsSpecialChars(str: string): boolean {
  const specialChars = /[^a-zA-Z0-9 )(\-_.,/]/
  return specialChars.test(str)
}

export function BookmarkEditForm(props: BookmarkEditFormProps) {
  // TODO: Fix the save edit/create to not close the dialog until the change is made, or tell the drawer to display skeleton loaders until it is finished
  const { onClose, bookmark, reportId, icon, open } = props

  const { mutateAsync: createBookmark, isLoading: isSaving } =
    useCreateBookmark(reportId)
  const { mutateAsync: updateBookmark, isLoading: isSavingEdit } =
    useUpdateBookmark(reportId)
  const { mutateAsync: addBookmarkMember, isLoading: isSharing } =
    useAddBookmarkMember()
  const { mutateAsync: removeBookmarkMember, isLoading: isRemoving } =
    useRemoveBookmarkMember()

  const { data: isShared, refetch: refetchIsShared } = useGetBookmarkMember(
    bookmark?.id
  )

  const [bookmarkState, setBookmarkState] = useRecoilState(bookmarkAtom)

  const [name, setName] = useState(bookmark?.name || '')
  const [description, setDescription] = useState(bookmark?.description || '')
  const [share, setShare] = useState<boolean>(isShared || false)

  const toggleShare = () => {
    setShare(o => !o)
  }

  const [titleErrorMessage, setTitleErrorMessage] = useState('')
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('')

  const { data: license } = useGetLicense()
  const reportState = useRecoilValue(reportStateAtom)
  const setBookmarkId = (id: string) => {
    window.history.replaceState({}, '', `/reports/${reportId}/${id}`)
  }

  useEffect(() => {
    setShare(isShared)
    setName(bookmark?.name)
    setDescription(bookmark?.description)
  }, [isShared, bookmark?.name, bookmark?.description])

  const handleShare = () => {
    toggleShare()
  }

  async function handleSave() {
    try {
      let newBookmark: NewBookmark = {
        id: bookmark?.id,
        name,
        description,
        state: bookmark ? bookmark.state : reportState,
        icon: icon || 'default',
        licenseNodeId: license.id,
        reportId,
      }

      let bookmarkId = bookmark?.id
      if (!bookmark) {
        bookmarkId = (await createBookmark(newBookmark))?.id || null
        if (bookmarkId) setBookmarkId(bookmarkId)
      } else {
        await updateBookmark(newBookmark)
      }
      bookmarkId = bookmarkId || bookmark?.id
      if (!bookmarkId) {
        onClose()
        return
      }

      if (share !== isShared) {
        if (share) {
          await addBookmarkMember({ bookmarkId })
        } else {
          await removeBookmarkMember({ bookmarkId })
        }
        refetchIsShared()
      }
      setBookmarkState({
        ...bookmarkState,
        selectedBookmark: newBookmark,
      })
      onClose()
    } catch (e) {
      onClose()
    }
  }

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title='Add Bookmark'
      primaryButtonProps={{
        onClick: handleSave,
        disabled: !name,
        children: 'Save',
      }}
      secondaryButtonProps={{
        onClick: onClose,
        children: 'Cancel',
      }}
      loading={isSaving || isSavingEdit || isSharing || isRemoving}
    >
      <FormField
        required
        label='Bookmark Title'
        value={name}
        errorMessage={titleErrorMessage}
        onTextChange={value => {
          if (value.length > 50) {
            setTitleErrorMessage('This field cannot exceed 50 characters')
          } else if (containsSpecialChars(value)) {
            setTitleErrorMessage(
              'This field cannot contain the following characters: ~ ! @ # $ % ^ & * < > ? | ; : \' "  \\ '
            )
          } else {
            setTitleErrorMessage('')
            setName(value)
          }
        }}
      />
      <FormField
        isTextArea={true}
        label='Description'
        value={description}
        errorMessage={descriptionErrorMessage}
        onTextChange={value => {
          if (value.length > 200) {
            setDescriptionErrorMessage(
              'This field cannot exceed 200 characters'
            )
          } else {
            setDescriptionErrorMessage('')
            setDescription(value)
          }
        }}
      />
      <Box style={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
        <FormControlLabel
          control={
            <Switch
              checked={share}
              onChange={handleShare}
              name='share'
              color='primary'
            />
          }
          label={'Shared with License'}
          labelPlacement='start'
        />
      </Box>
    </CustomDialog>
  )
}

type BookmarkEditFormProps = {
  onClose: () => void
  reportId: string
  icon: string
  bookmark?: Bookmark
  open: boolean
}

export default BookmarkDialog
