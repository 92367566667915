import {
  alpha,
  Autocomplete,
  Box,
  FormControl,
  Icon,
  lighten,
  Stack,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import CustomDialog from '../../admin/shared/dialog/dialog'
import { FormField } from '../../admin/shared'
import { Folder, FolderItem } from '../../../models'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { useMemo, useState } from 'react'
import { DragDropContext, Draggable } from 'react-beautiful-dnd'
import { StrictModeDroppable } from '../create-custom-report/custom-report-table/strict-mode-dropable'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import {
  useGetAppSettings,
  useGetReports,
  //useGetBookmarks,
} from '../../../hooks'
import { FolderItemTypeToIcon } from './util'
import { makeStyles, createStyles } from '@mui/styles'
import { useGetFolders } from '../../../hooks'
import DeleteFolderDialog from '../../admin/tabs/users/dialogs/delete-folder-dialog'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      paper: {
        backgroundColor: lighten(theme.palette.primary.primaryColor400, 0.95),
        boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.25)',
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
      },
      labelStyling: {
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightBold,
      },
      searchBox: {
        padding: 0,
        '& .MuiOutlinedInput-root': {
          padding: 0,
          border: 0,
        },
      },
      itemsListSearchBox: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        '&:hover': {
          backgroundColor: theme.palette.primary.primaryColor400,
          color: theme.palette.primary.contrastText,
        },
      },
      fieldStyling: {
        border: '1px solid #C2C2C2',
        margin: 0,
        padding: 0,
        fontSize: theme.typography.fontSize,
        '& input': {
          fontSize: theme.typography.fontSize,
        },
        '& input::placeholder': {
          fontSize: theme.typography.fontSize,
        },
        '& .MuiAutocomplete-inputRoot:before': {
          //boxShadow: '0 0 3px #0082E4 !important',
        },
        '& .MuiInput-root': {
          '&:hover': {
            border: 0,
            boxShadow: '0 0 3px #0082E4 !important',
            '&:before': {
              border: 0,
            },
          },
          '&:focus': {
            boxShadow: '0 0 3px #0082E4',
          },
          '&:active': {
            border: 0,
            boxShadow: '0 0 3px #0082E4',
          },
          '&::selected': {
            border: 0,
            boxShadow: '0 0 3px #0082E4',
          },
          '&:before': {
            border: 0,
          },
          // Border on focus
          '&:after': {
            border: 0,
            boxShadow: '0 0 3px #0082E4',
          },
        },
      },
      itemsSearchBox: {
        marginTop: theme.spacing(0),
        fontSize: theme.typography.fontSize,
      },
      itemsSearch: {
        fontSize: theme.typography.fontSize,
      },
      bookmarkIcon: {
        height: '10px',
      },
    }),
  { name: 'loading-button' }
)

function containsSpecialChars(str: string): boolean {
  const specialChars = /[^a-zA-Z0-9 )(\-_.,/]/
  return specialChars.test(str)
}

function ManageFolderDialog(props: ManageFolderDialogProps) {
  const {
    open,
    onClose,
    folder,
    createFolder,
    updateFolder,
    deleteFolder,
    isLoading,
  } = props
  const id = folder?.id ?? undefined
  const isFavorites = folder?.isFavorites ?? false

  const [tempFolder, setTempFolder] = useState<Folder>({
    id: folder?.id,
    name: folder?.name ?? '',
    description: folder?.description ?? '',
    items: folder?.items ?? [],
  })

  const [folderNameErrorMessage, setFolderNameErrorMessage] = useState('')
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('')
  const { data: appSettings } = useGetAppSettings()
  const [showDeleteFolderDialog, setShowDeleteFolderDialog] = useState(false)

  const classes = useStyles()

  const handleOnClose = () => {
    onClose()
  }

  const handleDragEnd = async (result: any) => {
    if (!result.destination) return
    const sortableItems = Array.from(tempFolder?.items)
    const [reorderedItem] = sortableItems.splice(result.source.index, 1)
    sortableItems.splice(result.destination.index, 0, reorderedItem)
    setTempFolder({ ...tempFolder, items: sortableItems })
  }

  const folderLimitExists =
    appSettings?.folderLimit && appSettings?.folderLimit > 0

  const folderDescriptionValue =
    tempFolder.description.length <= 1 ? ' ' : tempFolder.description

  const folderNameFilled = tempFolder.name ? false : true

  const { data: folders } = useGetFolders()

  const duplicateFolderName =
    folders.some(item => {
      if (
        item[`name`].toLowerCase().trim() ===
          tempFolder.name.toLowerCase().trim() &&
        item[`id`] === tempFolder.id
      ) {
        return false
      } else if (
        item[`name`].toLowerCase().trim() ===
        tempFolder.name.toLowerCase().trim()
      ) {
        return true
      } else {
        return false
      }
    }) ||
    tempFolder.name.toLowerCase().trim() === 'my reports' ||
    tempFolder.name.toLowerCase().trim() === 'my bookmarks'

  return (
    <CustomDialog
      open={open}
      onClose={() => {
        handleOnClose()
      }}
      paperSx={{
        minWidth: '600px',
        height: '640px',
      }}
      allowEscapeKeyClose
      allowBackdropClickClose
      title={id === undefined ? 'Add New Folder' : 'Edit Folder'}
      loading={isLoading}
      tertiaryButtonProps={
        id === undefined
          ? null
          : {
              children: 'Delete Folder',
              disabled: isFavorites,
              onClick: async () => setShowDeleteFolderDialog(true),
            }
      }
      primaryButtonProps={{
        children: id === undefined ? 'Create' : 'Save',

        //startIcon: <CheckOutlinedIcon />,
        disabled: isLoading || folderNameFilled || duplicateFolderName, //isUpdatingFolder,
        onClick: async () => {
          if (id === undefined) {
            //create a new folder
            await createFolder({
              folderName: tempFolder.name,
              folderDescription: tempFolder.description,
              items: tempFolder.items,
              //some how we need to pass the report group (analytic type) here maybe
            })
          } else {
            await updateFolder({
              folderId: id,
              folderName: tempFolder.name,
              folderDescription: folderDescriptionValue,
              items: tempFolder.items,
            })
          }
          handleOnClose()
        },
      }}
      secondaryButtonProps={{
        children: 'Cancel',
        onClick: () => {
          handleOnClose()
        },
        variant: 'text',
        //startIcon: <CloseOutlinedIcon />,
      }}
    >
      <Stack direction='column'>
        <FormField
          // required
          label='Folder Name'
          required={true}
          value={tempFolder.name}
          helperText=''
          errorMessage={folderNameErrorMessage}
          //disabled={isFavorites}
          onTextChange={value => {
            const isDuplicateFolderName =
              folders.some(item => {
                if (
                  item[`name`].toLowerCase().trim() ===
                  value.toLowerCase().trim()
                ) {
                  return true
                } else {
                  return false
                }
              }) ||
              value.toLowerCase().trim() === 'my reports' ||
              value.toLowerCase().trim() === 'my bookmarks'

            if (value.length > 50) {
              setFolderNameErrorMessage(
                'This field cannot exceed 50 characters'
              )
            } else if (containsSpecialChars(value)) {
              setFolderNameErrorMessage(
                'This field cannot contain the following characters: ~ ! @ # $ % ^ & * < > ? | ; : \' "  \\ '
              )
            } else if (isDuplicateFolderName === true) {
              setFolderNameErrorMessage('Duplicate folder name not allowed')
              setTempFolder({ ...tempFolder, name: value })
            } else {
              setFolderNameErrorMessage('')
              setTempFolder({ ...tempFolder, name: value })
            }
          }}
        />
        <FormField
          label='Description'
          isTextArea={true}
          value={tempFolder.description}
          helperText=''
          errorMessage={descriptionErrorMessage}
          onTextChange={value => {
            if (value.length > 200) {
              setDescriptionErrorMessage(
                'This field cannot exceed 200 characters'
              )
            } else {
              setDescriptionErrorMessage('')
              setTempFolder({ ...tempFolder, description: value })
            }
          }}
        />
        <ItemsSearch
          onAddItem={(item: any) => {
            const newItem: FolderItem = {
              id: item.id,
              name: item.name,
              type: item.type || 'report',
            }
            const newItems = [...tempFolder?.items, newItem]
            setTempFolder({ ...tempFolder, items: newItems })
          }}
          items={tempFolder.items}
          disabled={
            folderLimitExists &&
            tempFolder.items?.length >= appSettings?.folderLimit
          }
        />
        <Stack
          direction='row'
          justifyContent={'space-between'}
          alignItems='center'
          sx={{ mt: 0 }}
        >
          <Typography variant='body2' className={classes.labelStyling}>
            Folder Items
          </Typography>
          {folderLimitExists && (
            <Typography variant='body2'>{`${tempFolder?.items?.length}/${appSettings?.folderLimit}`}</Typography>
          )}
        </Stack>
        <DragDropContext onDragEnd={handleDragEnd}>
          <StrictModeDroppable droppableId='droppable'>
            {provided => (
              <Stack
                direction='column'
                gap={1}
                sx={{
                  py: 0,
                  px: 1,
                  minWidth: '332px',
                  maxHeight: '240px',
                  overflow: 'hidden',
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    overflowY: 'scroll',
                    maxHeight: '100%',
                    position: 'relative',
                    //hide scroll bar
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                    scrollbarWidth: 'none',
                    pr: 1,
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      zIndex: 0,
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {!tempFolder?.items?.length ? (
                      <Typography
                        sx={{
                          mt: 1,
                          color: theme => theme.palette.text.disabled,
                        }}
                      >
                        No items added
                      </Typography>
                    ) : null}
                    {tempFolder?.items?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id + index}
                        index={index}
                      >
                        {provided => (
                          <SingleFolderItem
                            item={item}
                            provided={provided}
                            onRemove={() => {
                              const newItems = tempFolder?.items?.filter(
                                (folderItem: any) => folderItem.id !== item.id
                              )
                              setTempFolder({ ...tempFolder, items: newItems })
                            }}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <Box sx={{ height: '52px' }} />
                  </Box>
                </Box>

                {tempFolder?.items?.length > 5 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      maxHeight: '20px',
                      background: `linear-gradient(to bottom, 
        rgba(255, 255, 255, 0.5) 0%, 
        rgba(255, 255, 255, 1) 100%)`,
                      width: '100%',
                      pointerEvents: 'none',
                      zIndex: 1,
                    }}
                  />
                )}
              </Stack>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </Stack>
      <DeleteFolderDialog
        open={showDeleteFolderDialog}
        onClose={() => setShowDeleteFolderDialog(false)}
        folder={folder}
        handleDelete={async () => {
          await deleteFolder({
            folderId: id,
          })
          handleOnClose()
        }}
        isDeleting={isLoading}
      />
    </CustomDialog>
  )
}

type ItemsSearchProps = {
  onAddItem: (item: any) => void
  items: FolderItem[]
  disabled?: boolean
}

function ItemsSearch(props: ItemsSearchProps) {
  const { onAddItem, items, disabled } = props
  const { data: reports } = useGetReports()
  // const { data: bookmarks } = useGetBookmarks()
  const [clearValueToggle, setClearValueToggle] = useState(false)
  const classes = useStyles()

  const myReports = reports?.ownedReports || []
  const sharedReports = reports?.sharedReports || []
  const groupedReports = reports?.groupedReports || []
  const allReports = [...myReports, ...sharedReports, ...groupedReports]

  const filteredReports = useMemo(() => {
    return allReports?.filter(
      report => !items.find(item => item?.id === report?.id)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.items, allReports])

  const uniqueFilteredReports = filteredReports.filter(
    (obj, index, self) => index === self.findIndex(o => o['id'] === obj['id'])
  )

  return (
    <Stack className={classes.itemsSearchBox} direction='column' gap={1}>
      <FormControl fullWidth variant='outlined'>
        <Typography className={classes.labelStyling}>Add Items</Typography>
        <Autocomplete
          noOptionsText={'No available Bookmarks or Reports'}
          disabled={disabled}
          // disablePortal
          id='select-items'
          key={clearValueToggle + 'autocomplete'}
          options={uniqueFilteredReports}
          getOptionLabel={option => option.name}
          onChange={(_event, newValue) => {
            if (!newValue) return
            onAddItem(newValue)
            setClearValueToggle(prev => !prev)
          }}
          renderOption={(props, option: FolderItem) => (
            <li {...props} className={classes.itemsListSearchBox}>
              <Stack
                direction='row'
                className={`${classes.labelStyling}`}
                gap={1}
                alignItems='center'
              >
                <Typography className={classes.itemsSearch}>
                  {option.name}
                </Typography>
                {option?.type === 'bookmark' ? (
                  <FolderItemTypeToIcon
                    type={option?.type}
                    sx={{ fontSize: '16px' }}
                  />
                ) : (
                  ''
                )}
              </Stack>
            </li>
          )}
          value={undefined}
          fullWidth
          //drop down styles background color:
          sx={{
            display: 'block',
            height: '40px',
            // backgroundColor: theme => alpha(theme.palette.primary.primaryColor400, 0.3),
          }}
          classes={{
            paper: classes.paper,
          }}
          disableClearable
          renderInput={params => (
            <TextField
              {...params}
              className={classes.fieldStyling}
              placeholder=' Search Bookmark, Report'
              //label={isDropdownOpen ? 'Select Items' : 'Add Items'}
              variant='standard'
            />
          )}
        />
      </FormControl>
    </Stack>
  )
}

type SingleFolderItemProps = {
  item: any
  provided: any
  onRemove: () => void
}

function SingleFolderItem({ item, provided, onRemove }: SingleFolderItemProps) {
  const classes = useStyles()
  return (
    <Stack
      ref={provided.innerRef}
      {...provided.draggableProps}
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{
        mt: theme => theme.spacing(1),
        '&:hover': {
          backgroundColor: theme =>
            alpha(theme.palette.primary.primaryColor400, 0.3),
        },
        border: '1px solid #E0E0E0',
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        gap={1}
        {...provided.dragHandleProps}
        sx={{
          cursor: 'pointer',
        }}
      >
        <DragIndicatorIcon
          sx={{
            color: theme => theme.palette.text.disabled,
            cursor: 'grab',
          }}
        />
        <Typography className={classes.itemsSearch}>{item.name}</Typography>
        {item?.type === 'bookmark' ? (
          <FolderItemTypeToIcon type={item?.type} sx={{ fontSize: '16px' }} />
        ) : (
          ''
        )}
      </Stack>
      <Tooltip title='Remove' arrow={true}>
        <Icon
          onClick={onRemove}
          sx={{
            cursor: 'pointer',
            color: 'red',
          }}
        >
          <DeleteOutlineOutlinedIcon />
        </Icon>
      </Tooltip>
    </Stack>
  )
}

type ManageFolderDialogProps = {
  open: boolean
  onClose: () => void
  folder?: Folder
  createFolder: (folder: any) => void
  updateFolder: (folder: any) => void
  deleteFolder: (folder: any) => void
  isLoading?: boolean
}

export default ManageFolderDialog
//
